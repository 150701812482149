import { createContext, useRef, useState, useEffect } from 'react';
import { trackEvent } from 'helper/mixPanel';
import { socketEmit } from 'services/socket';
import { getAuth } from 'services/auth.service';
import { toast } from 'react-toastify';

export const StatusContext = createContext();

export const StatusProvider = ({ children }) => {
    const [callStatus, setCallStatus] = useState(null);
    const [inputDeviceId, setInputDeviceId] = useState('');
    const [userStatus, setUserStatus] = useState('');
    const [globalCommand, setGlobalCommand] = useState(null);
    const [brokers, setBrokers] = useState([]);
    const [isDeletedPrices, setIsDeletedPrices] = useState(false);

    // quick price entry
    const [quickPrice, setQuickPrice] = useState({});
    const quickPriceRowValueRef = useRef({}); // row value for quick price entry
    const isIgBondRef = useRef(null);
    const rowValueRef = useRef({});
    const bondPriceRef = useRef("");
    const bondQuantityRef = useRef("");
    const tradeTypeRef = useRef("");
    const cleanupFunctionsListRef = useRef([])

    const auth = getAuth();

    // Function to run multiple cleanup functions
    const quickPriceEntryCleanup = (event) => {
        if (cleanupFunctionsListRef.current.length === 0) return;
        const funs = cleanupFunctionsListRef.current;

        funs.forEach(fn => fn(event));

        cleanupFunctionsListRef.current = []
    }

    const generateQTPObject = (type = "Bid") => {
        const priceType = isIgBondRef ? quickPrice?.priceType : null;
        const isBroker = auth?.role === 'Broker';


        if (bondPriceRef.current <= 0) {
            const errorMsg = 'Price must be greater than or equal to 0.001'
            toast.error(errorMsg, { toastId: "error-for-invalid-price" });
        }

        if (bondQuantityRef.current <= 0) {
            const errorMsg = ' Size cannot be less than 1 Million';
            toast.error(errorMsg, { toastId: "error-for-invalid-size" });
        }

        if (bondPriceRef.current <= 0 || bondQuantityRef.current <= 0) {
            throw new Error(errorMsg)
        }

        const bondPriceDetails = {
            id: rowValueRef.current.id, //*
            bondId: rowValueRef.current.bondId,
            traded: false,
            size: bondQuantityRef.current,
            userId: auth?.id, //*
            bankDealerExclude: false,
            price: bondPriceRef.current,
            type: type === "Bid" ? 'Bid' : 'Offer',
            brokerId: quickPrice?.broker?.brokerId,
            companyId: quickPrice?.broker?.value,
            traderId: isBroker ? quickPrice?.trader?.id || null : auth?.id, //*
            userCompanyId: type === "Bid"
                ? isBroker ? quickPrice?.trader?.companyId || null : auth?.companyId  // for bid  
                : auth?.companyId, //for offer
            icebergSize: null,
            comment: null,
            ...(priceType && { priceType })
        };

        const mixPanelObject = {
            'Asset Type': rowValueRef.current.bondType.toUpperCase(),
            'Sub Asset Type': rowValueRef.current.bondLevel,
            'User Id': auth.id,
            Size: bondQuantityRef.current,
            Type: type === 'Bid' ? 'Bid' : 'Offer',
            'Is Iceberg': 'No',
            'Broker Company': quickPrice?.broker?.label,
            'Trader Company': isBroker ? quickPrice?.trader?.companyName || null : auth?.companyName, //*
            Name: rowValueRef.current.bondName
        };

        return { bondPriceDetails, mixPanelObject }
    }

    const handleBid = () => {
        try {
            const { bondPriceDetails, mixPanelObject } = generateQTPObject(tradeTypeRef.current)

            socketEmit('/create-bond-price', bondPriceDetails);
            trackEvent('Add Bid/Offer', mixPanelObject);
        } catch (error) {
            throw new error('INVALID VALUE ', error)
        }
    };


    return (
        <StatusContext.Provider value={{
            callStatus,
            setCallStatus,
            inputDeviceId,
            setInputDeviceId,
            userStatus,
            setUserStatus,
            globalCommand,
            setGlobalCommand,
            brokers,
            setBrokers,
            isDeletedPrices,
            setIsDeletedPrices,

            // quick price entry 
            quickPrice,
            setQuickPrice,
            quickPriceRowValueRef,
            handleBid,
            isIgBondRef,
            rowValueRef,
            bondPriceRef,
            bondQuantityRef,
            tradeTypeRef,
            cleanupFunctionsListRef,
            quickPriceEntryCleanup
        }}>
            {children}
        </StatusContext.Provider>
    );
};
