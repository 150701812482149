import classnames from 'classnames';
import Image from 'next/image';
import PropTypes from 'prop-types';

const buttonStyles = {
    primary: {
        default: 'bg-primary-300 border border-transparent text-gray-50 focus:bg-primary-300 active:bg-primary-300 visited:bg-primary-300',
        gray: 'bg-gray-100 border border-transparent text-gray-50 focus:bg-gray-100 active:bg-gray-100 visited:bg-gray-100',
        dull: '!text-gray-200 bg-gray-800'
    },
    ghost: {
        default: 'text-primary-300 border border-primary-300 focus:bg-primary-300 focus:text-gray-50 visited:text-gray-50 focus:outline-2 visited:bg-primary-300',
        gray: 'text-gray-100 border border-gray-400 focus:outline-2 visited:text-gray-300'
    },
    borderLess: {
        default: 'text-primary-300 border border-transparent bg-transparent disabled:!bg-transparent',
        gray: 'text-gray-100 border border-transparent bg-transparent'
    }
};

const iconPositionsStyle = {
    left: 'flex-row',
    right: 'flex-row-reverse space-x-reverse'
};

const Button = ({
    iconPosition,
    label,
    onClick,
    type,
    fullWidth,
    className,
    size,
    disabled,
    btnClass,
    iconComponent,
    buttonTheme,
    title,
    loading,
    btnRef,
    onKeyDown,
    textSize,
    iconClass
}) => {
    const buttonSizes = {
        tiny: `py-[3px] px-4 font-semibold leading-5 ${textSize || 'text-sm'}`,
        small: `py-[11px] px-6 font-semibold ${textSize || 'text-sm'}`,
        extraSmall: `py-[5px] px-6 font-semibold ${textSize || 'text-base'}`,
        medium: `py-[9px] px-8 font-semibold ${textSize || 'text-base'}`,
        large: `py-[15px] px-8 font-semibold ${textSize || 'text-xl'}`
    };

    const defaultStyle = buttonStyles[type]?.default || buttonStyles.primary.default;
    const themeStyle = buttonStyles[type]?.[buttonTheme] || defaultStyle;

    const buttonClasses = classnames(
        'transition-all rounded-md disabled:cursor-not-allowed disabled:placeholder-primary-100 disabled:text-primary-100 disabled:bg-primary-110 disabled:outline-none disabled:border-transparent disabled:transform-none transform transition-transform  duration-300 flex items-center space-x-2 disabled:opacity-50',
        themeStyle,
        fullWidth && 'w-full justify-center',
        className,
        btnClass,
        label && buttonSizes[size],
        iconPositionsStyle[iconPosition],
        !loading && 'hover:opacity-80 active:scale-90'
    );

    return (
        <button
            {...(btnRef && { ref: btnRef })}
            {...(onKeyDown && { onKeyDown })}
            title={title}
            type="button"
            onClick={onClick}
            className={`flex items-center gap-1 ${buttonClasses} ${disabled && '!bg-primary-110 !text-primary-100 hover:bg-primary-110 active:bg-primary-110'}`}
            disabled={disabled}
        >
            <div className={`flex ${iconComponent && 'space-x-2'} items-center select-none relative ${iconClass} ${iconPositionsStyle[iconPosition]}`}>
                <div className={`${loading && 'invisible'}`}>{iconComponent && iconComponent}</div>
                <span className={`${loading && 'invisible'}`}> {label}</span>
                {loading
                    && <div className='absolute top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%]'>
                        <Image src="/images/loader.svg" width="22" height="22" alt='loading' className="animate-spin" />
                    </div>
                }
            </div>
        </button>
    );
};

Button.propTypes = {
    type: PropTypes.oneOf(Object.keys(buttonStyles)),
    size: PropTypes.oneOf(['small', 'extraSmall', 'medium', 'large']),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    disabled: PropTypes.bool,
    iconComponent: PropTypes.node,
    onClick: PropTypes.func,
    fullWidth: PropTypes.bool,
    className: PropTypes.string,
    btnClass: PropTypes.string,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    buttonTheme: PropTypes.string,
    loading: PropTypes.bool,
    textSize: PropTypes.string
};

Button.defaultProps = {
    iconComponent: null,
    iconPosition: 'left',
    label: '',
    onClick: () => { },
    type: 'primary',
    fullWidth: false,
    className: '',
    disabled: false,
    size: 'small',
    btnClass: '',
    buttonTheme: 'default',
    loading: false
};

export default Button;
