import { StatusContext } from '@/components/context/StatusContext';
import Text from 'atoms/Text';
import { popupSpecsForDes } from 'lib/constants';
import debounce from 'lodash.debounce';
import { nanoid } from 'nanoid';
import dynamic from 'next/dynamic';
import {
    useContext, useEffect, useRef, useState
} from 'react';
import 'regenerator-runtime/runtime';
import { getAuth } from 'services/auth.service';
import { socket, socketConnect, socketEmit } from 'services/socket';
import SearchBox from '../atoms/SearchBox';
import { trackEvent } from '../helper/mixPanel';

const Dictaphone = dynamic(() => import('../components/Dictaphone'), {
    ssr: false
});

const GlobalSearch = ({
    icon, iconClass, options = [], onSelect, placeholder, clubUsersIntoFunctions = false, inputClass, forDashboard = true, forDetailsPage = false,
    suggestionBg, categoryTitleClass, categoryList, highlightBg, suggestionName, globalSearchList,
    takeScreenShot, selectedCommand = 'DES', setYasPrice = () => { }, voiceCommand = false, plot = () => { }
}) => {
    const enableVoiceCommand = false;
    const [searchPhrase, setSearchPhrase] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const inputRef = useRef(null);
    const [highlightedId, setHighlightedId] = useState('');
    const parentIndex = useRef(0);
    const childIndex = useRef(-1);
    const [commandsAndUsers, setCommandsAndUsers] = useState(options);
    const allSuggestions = [...commandsAndUsers, ...suggestions];
    const searchBoxRef = useRef(null);
    const yasWithPriceRegex = /^yas (\d+(\.\d+)?)$/;
    const matchYasCommandWithPrice = (str) => yasWithPriceRegex.test(str);

    const { globalCommand } = useContext(StatusContext);
    const auth = getAuth();

    const getPriceFromYasWithPrice = (str) => {
        const match = str.match(yasWithPriceRegex);
        if (match) {
            return match[1];
        }
        return null;
    };
    const parsePlot = (text) => {
        const pattern = /^(.+?)\s((?:\d+[mywd]\s?)*)$/;
        const match = text.toLowerCase().trim().match(pattern);
        const plotPattern = /^plot\s+(.*?)((?:\d+[mywd]\s?)+)$/;
        if (plotPattern.test(text.toLowerCase().trim())) {
            const match = text.toLowerCase().trim().match(plotPattern);
            const name = match[1].trim();
            const tenors = match[2].trim().split(/\s+/);
            const isValid = tenors.every((tenor) => /^\d+[mywd]$/.test(tenor)) && (!name || tenors.length > 1);
            return { name, tenors, isValid };
        }

        if (match) {
            const name = match[1].trim();
            const tenors = match[2].trim().split(/\s+/);
            const isValid = tenors.every((tenor) => /^\d+[mywd]$/.test(tenor)) && tenors.length > 1;
            return { name, tenors, isValid };
        }

        return {
            isValid: false
        };
    };

    useEffect(() => {
        if (!forDashboard) {
            searchBoxRef.current.focus();
        }
    }, []);

    const getResults = (value) => {
        socketEmit('/global-search', { phrase: value });
    };
    const getResults2 = (data) => {
        socketEmit('/search/voice', data);
    };
    const debouncedInputHandler = useRef(debounce(getResults, 800, { leading: false }));
    const debouncedInputHandler2 = useRef(debounce(getResults2, 300, { leading: false }));

    const handleInputChange = (e) => {
        setSearchPhrase(e.target.value);
        if (!e.target.value) { setSuggestions(() => []); setCommandsAndUsers(options); return; }
        if (!forDashboard && e.target.value.length) setShowSuggestions(() => true);
        if (e.target.value.length < 2) return;
        debouncedInputHandler.current(e.target.value);
    };

    const getFilteredCommands = (searchPhrase) => options[0].items
        .filter((optionItem) => optionItem.command.toLowerCase().includes(searchPhrase.toLowerCase())
            || optionItem.label.toLowerCase().includes(searchPhrase.toLowerCase())
            || (optionItem.command.toLowerCase() === 'yas' && matchYasCommandWithPrice(searchPhrase.toLowerCase())));

    const mixPanelEvent = (type, results, ref) => {
        const count = results?.reduce((acc, curr) => acc + curr?.items?.length, 0);
        const searchQuery = ref?.current?.value;
        trackEvent('Search', {
            'Search Query': searchQuery,
            Count: count,
            'User Id': auth.id,
            Type: type
        });
    };

    const onResultsFetch = ({ results, error }) => {
        if (error) { setSuggestions([]); return; }
        if (!results.length) setSuggestions([]);
        const suggestions = results;
        if (clubUsersIntoFunctions) {
            const userCategory = suggestions.find((suggestion) => suggestion.category === 'Users');
            if (userCategory) {
                const formattedUsers = userCategory.items.map((user) => (
                    {
                        id: user.id, group: 'Users', command: `BIO ${user.name}`, label: ` ${user.companyName}`, type: 'Function'
                    }));
                setSearchPhrase((prevState) => {
                    const formattedCommands = getFilteredCommands(prevState);
                    if (formattedCommands.length || formattedUsers.length) {
                        setCommandsAndUsers([{ category: 'Functions', items: [...formattedCommands, ...formattedUsers] }]);
                    } else {
                        setCommandsAndUsers([]);
                    }
                    return prevState;
                });
            }
            if (searchBoxRef?.current?.value) {
                mixPanelEvent('Command Prompt', results, searchBoxRef);
            }
            setSuggestions(suggestions.filter((suggestion) => suggestion.category !== 'Users'));
            return;
        }
        if (searchBoxRef?.current?.value) {
            mixPanelEvent('Global Search', results, searchBoxRef);
        }
        setSuggestions(suggestions);
    };

    useEffect(() => {
        allSuggestions.every((item, index1) => {
            if (item.category === 'Functions') {
                item.items.every((item2, index2) => {
                    if ((item2.command.toLowerCase() === searchPhrase.toLowerCase())
                        || (item2.command.toLowerCase() === 'yas' && matchYasCommandWithPrice(searchPhrase.toLowerCase()))
                    ) {
                        parentIndex.current = index1;
                        childIndex.current = index2;
                        setHighlightedId(`${allSuggestions[index1].category}-${index2}`);
                        return false;
                    }
                    if (highlightedId) {
                        setHighlightedId(() => '');
                        parentIndex.current = 0;
                        childIndex.current = -1;
                    }

                    return true;
                });
            }
            return true;
        });
    }, [commandsAndUsers]);

    useEffect(() => {
        if (searchPhrase === 'PLOT ') return;
        if (!searchPhrase.length) {
            setSuggestions(() => []);
            setShowSuggestions(() => false);
            return;
        }
        if (searchPhrase.length) {
            setShowSuggestions(() => true);

            if (forDetailsPage) {
                const filteredCommands = getFilteredCommands(searchPhrase);
                if (filteredCommands.length) {
                    setCommandsAndUsers(() => [{ category: 'Functions', items: filteredCommands }]);
                } else {
                    setCommandsAndUsers([]);
                }
            }
        }
    }, [searchPhrase]);

    const resetCursor = () => {
        childIndex.current = -1;
        parentIndex.current = 0;
        setHighlightedId('');
    };

    const handleClickOutside = (e) => {
        if (inputRef.current && !inputRef.current.contains(e.target)) {
            setShowSuggestions(false);
            !forDashboard && setSearchPhrase(() => '');
            resetCursor();
        }
    };
    const onPlotFetch = (data) => {
        const plotType = { irs: 'irs', currency: 'currencies' };
        if (data.error) return;
        const url = `${process.env.NEXT_PUBLIC_UI_URL}plot/${plotType[data.type]}/${data.id}?legs=${data.term}`;
        if (forDetailsPage) openInSamePage(url);
        else openTargetPage(url);
        setSearchPhrase('');
        resetCursor();
    };

    useEffect(() => {
        socketConnect();
        socket.on('/global-search', onResultsFetch);
        socket.on('/search/voice', onResultsFetch);
        socket.on('/plots/search', onPlotFetch);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            socket.off('/global-search', onResultsFetch);
            socket.off('/search/voice', onResultsFetch);
            socket.off('/plots/search', onPlotFetch);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const scrollElementToView = (id, start = false) => {
        const div = document.getElementById('search-dropdown');
        if (!div) return;
        const divRect = div.getBoundingClientRect();
        const elem = document.getElementById(id);
        if (elem) {
            const rect = elem.getBoundingClientRect();

            if (divRect.bottom < rect.bottom || divRect.top > rect.top) {
                elem.scrollIntoView({ behavior: 'smooth', block: start ? 'start' : 'nearest', inline: start ? 'nearest' : 'start' });
            }
        }
    };
    useEffect(() => {
        if (suggestions.length) {
            const timeoutId = setTimeout(() => {
                parentIndex.current = 0;
                childIndex.current = 0;
                const hash = `${allSuggestions[parentIndex.current]?.category}-${childIndex.current}`;
                setHighlightedId(hash);
                scrollElementToView(hash);
            }, 200);

            return () => clearTimeout(timeoutId);
        }
    }, [suggestions]);

    const openTargetPage = (url) => {
        window.open(url, nanoid(5), popupSpecsForDes);
    };
    const openInSamePage = (url) => {
        window.open(url, '_self');
    };
    const onClick = (item) => {
        const baseUrl = process.env.NEXT_PUBLIC_UI_URL;
        const urlMap = {
            Bond: (bond) => `${baseUrl}bonds/${bond.id}?command=${selectedCommand}`,
            Irs: (irsSecurity) => `${baseUrl}irs/${irsSecurity.id}?command=${selectedCommand}`,
            Index: (bond) => `${baseUrl}indexes/${bond.id}?command=${selectedCommand}`,
            Cds: (index) => `${baseUrl}indexes/${index.id}?command=${selectedCommand}`,
            Equity: (equity) => `${baseUrl}equities/${equity.ticker}?command=${selectedCommand}`,
            User: (user) => `${baseUrl}users/${user.id}`,
            Trsy: `${baseUrl}trsy`,
            Ndf: (ndf) => `${baseUrl}currencies/${ndf.id}?command=${selectedCommand}`,
            Currency: (currency) => `${baseUrl}equities/${currency.ticker}?stockExchange=forex&command=${selectedCommand}`

        };

        if (item.type === 'Function') {
            if (item.group === 'Users') {
                openInSamePage(urlMap.User(item));
            } else if (item.command === 'SHARE') {
                takeScreenShot();
            } else if (item.command === 'PLOT') {
                setSearchPhrase('PLOT ');
            } else {
                const commandSelected = item.command;
                onSelect(commandSelected === 'RFQ' ? `${commandSelected}-${Date.now()}` : commandSelected);
            }
        } else if (forDetailsPage) {
            openInSamePage(urlMap[item.type](item));
        } else openTargetPage(urlMap[item.type](item));
        setShowSuggestions(false);
        setSuggestions([]);
        setCommandsAndUsers(options);
        if (item.command !== 'PLOT') setSearchPhrase('');
        resetCursor();
        !forDashboard && searchBoxRef.current.focus();
    };
    const scrollElement = (id, start = false) => {
        const div = document.getElementById('search-dropdown');
        let scroll = false;
        if (!div) return scroll;
        const divRect = div.getBoundingClientRect();
        const elem = document.getElementById(id);
        if (elem) {
            const rect = elem.getBoundingClientRect();

            if (divRect.bottom < rect.bottom || divRect.top > rect.top) {
                elem.scrollIntoView({ behavior: 'smooth', block: start ? 'start' : 'end', inline: 'nearest' });
                scroll = true;
            }
        }
        return scroll;
    };
    const handleSelect = (number) => {
        if (number <= 0) {
            const hash = `${allSuggestions[parentIndex.current]?.category}-${childIndex.current}`;
            setHighlightedId(hash);
            scrollElement(hash, false);
            return;
        } if (childIndex.current < allSuggestions[parentIndex.current]?.items.length - 1) {
            childIndex.current += 1;
        } else if (parentIndex.current < allSuggestions?.length - 1) {
            parentIndex.current += 1;
            childIndex.current = 0;
        } else {
            parentIndex.current = 0;
            childIndex.current = 0;
        }
        handleSelect(number - 1);
    };
    const handleDown = (next) => {
        if (!next) return;
        if (childIndex.current < allSuggestions[parentIndex.current].items.length - 1) {
            childIndex.current += 1;
        } else if (parentIndex.current < allSuggestions.length - 1) {
            parentIndex.current += 1;
            childIndex.current = 0;
        } else {
            parentIndex.current = 0;
            childIndex.current = 0;
            next = false;
        }
        const hash = `${allSuggestions[parentIndex.current]?.category}-${childIndex.current}`;
        setHighlightedId(hash);
        const scroll = scrollElement(hash, true);
        if (!scroll) handleDown(next);
    };
    const handleUp = (next) => {
        if (!next) return;
        if (childIndex.current > 0) {
            childIndex.current -= 1;
        } else if (parentIndex.current > 0) {
            parentIndex.current -= 1;
            childIndex.current = allSuggestions[parentIndex.current].items.length - 1;
        } else {
            parentIndex.current = allSuggestions.length - 1;
            childIndex.current = allSuggestions[parentIndex.current].items.length - 1;
            next = false;
        }
        const hash = `${allSuggestions[parentIndex.current]?.category}-${childIndex.current}`;
        setHighlightedId(hash);
        const scroll = scrollElement(hash, false);
        if (!scroll) handleUp(next);
    };
    const handleKeyDown = (event) => {
        if (allSuggestions.length && event.code === 'ArrowDown') {
            if (childIndex.current < allSuggestions[parentIndex.current]?.items.length - 1) {
                childIndex.current += 1;
            } else if (parentIndex.current < allSuggestions?.length - 1) {
                parentIndex.current += 1;
                childIndex.current = 0;
            } else {
                parentIndex.current = 0;
                childIndex.current = 0;
            }
            const hash = `${allSuggestions[parentIndex.current]?.category}-${childIndex.current}`;
            setHighlightedId(hash);
            scrollElementToView(hash);
        }
        if (allSuggestions.length && event.code === 'ArrowUp') {
            if (childIndex.current > 0) {
                childIndex.current -= 1;
            } else if (parentIndex.current > 0) {
                parentIndex.current -= 1;
                childIndex.current = allSuggestions[parentIndex.current].items.length - 1;
            } else {
                parentIndex.current = allSuggestions.length - 1;
                childIndex.current = allSuggestions[parentIndex.current].items.length - 1;
            }
            const hash = `${allSuggestions[parentIndex.current].category}-${childIndex.current}`;
            setHighlightedId(hash);
            scrollElementToView(hash);
        }
        if (event.code === 'Enter') {
            if (matchYasCommandWithPrice(searchPhrase.toLowerCase())) {
                const item = options.find((suggestion) => suggestion.category === 'Functions').items.find((item) => item.command === 'YAS');
                if (item) {
                    setYasPrice(getPriceFromYasWithPrice(searchPhrase.toLowerCase()));
                    onClick(item);
                    return;
                }
            }
            if (parsePlot(searchPhrase).isValid) {
                const { name, tenors } = parsePlot(searchPhrase);
                if (!name && forDetailsPage) {
                    plot(tenors);
                    return;
                }
                socketEmit('/plots/search', { phrase: name, tenors });
                return;
            }
            if (childIndex.current === -1) return;
            const item = allSuggestions[parentIndex.current]?.items[childIndex.current];
            if (!item) return;
            onClick(item);
            setYasPrice(null);
            setShowSuggestions(false);
            resetCursor();
            forDashboard ? document.getElementById('global-search')?.blur() : searchBoxRef.current.focus();
        }
    };
    useEffect(() => {
        if (!globalCommand?.value) return;
        const commandText = globalCommand.value.toString().toLowerCase().trim();
        const functions = options.find((item) => item.category === 'Functions')?.items;
        const item = functions?.find((item) => item.command.toLowerCase() === commandText && item.group === 'Functions');
        if (globalCommand.type === 'command' && item) {
            setSearchPhrase('');
            if (item.command === 'SHARE') {
                takeScreenShot();
            } else {
                onSelect(item.command);
            }
        } else if (globalCommand.type === 'search') {
            searchBoxRef.current.focus();
            setSearchPhrase(commandText);
            debouncedInputHandler2.current({ phrase: commandText });
        } else if (globalCommand.type === 'scroll') {
            globalCommand.value === 'ArrowUp' ? handleUp(true) : handleDown(true);
        } else if (globalCommand.type === 'select') {
            parentIndex.current = 0;
            childIndex.current = -1;
            handleSelect(globalCommand.value);
        } else if (globalCommand.type === 'enter') {
            handleKeyDown({ code: 'Enter' });
        }
    }, [globalCommand]);

    const getDisplayName = (item) => {
        if (item.type === 'Equity') return `${item.name} (${item.ticker})`;
        if (item.type === 'Currency') return `${item.ticker}`;
        if (item.type === 'Function') return `${item.command} -`;
        return item.name;
    };

    const getAdditionalInfo = (item) => {
        if (item.type === 'User') return `- ${item.companyName}`;
        if (item.type === 'Function') return `${item.label}`;
    };

    return <div className='relative' ref={inputRef}>
        {(enableVoiceCommand && (forDetailsPage || voiceCommand)) && <Dictaphone />}
        <SearchBox
            type='text'
            id='global-search'
            value={searchPhrase}
            placeholder={placeholder}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            autoComplete='off'
            inputClass={`${inputClass} cursor-text`}
            icon={icon}
            iconClass={iconClass}
            onFocus={() => forDashboard && setShowSuggestions(true)}
            reference={searchBoxRef}
        />
        {showSuggestions && (
            <div id='search-dropdown' className={`absolute z-50 bg-gray-600 w-full rounded-md mt-0.5 overflow-y-auto max-h-96 ${globalSearchList} ${suggestionBg}`}
                onMouseEnter={resetCursor}>
                {allSuggestions.map((suggestion, index) => (
                    <div key={index} className="categoryBox">
                        <div className="px-3.5 py-1.5">
                            <Text variant="caption" className={`text-primary-100 ${categoryTitleClass}`}>
                                {suggestion.category}
                            </Text>
                        </div>
                        <div className="catList">
                            {suggestion.items.map((item, itemIndex) => (
                                <div id={`${suggestion.category}-${itemIndex}`}
                                    key={itemIndex}
                                    className={`flex justify-between group py-1.5 px-4 hover:bg-gray-500 cursor-pointer 
                                        ${`${suggestion.category}-${itemIndex}` === highlightedId && `bg-gray-500 ${highlightBg}`} ${categoryList}`}
                                    onClick={() => onClick(item)}
                                >
                                    <Text
                                        variant="caption"
                                        className={`text-gray-100 font-medium flex items-center gap-1 group-hover:text-gray-100 break-keep ${suggestionName}`}
                                    >
                                        {getDisplayName(item)}
                                        {(item.type === 'User' || item.type === 'Function') && !item.formattingStyle
                                            && <span className='text-warning-200 text-xs font-medium capitalize'>{getAdditionalInfo(item)}</span>
                                        }
                                        {item.formattingStyle === 'html'
                                            && <span className='text-warning-200 text-xs font-medium capitalize'>{item.html}</span>
                                        }

                                    </Text>
                                    {item.type !== 'Function'
                                        && <Text variant="caption" className={`text-gray-100 uppercase group-hover:text-gray-100 whitespace-pre ml-2 ${suggestionName}`}>
                                            {item.type === 'Ndf' ? 'FX' : item.type}
                                        </Text>}
                                </div>
                            ))}

                        </div>
                    </div>
                ))}
            </div>
        )
        }
    </div >;
};

export default GlobalSearch;
